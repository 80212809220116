import { render, staticRenderFns } from "./BlokSectionFeatureTabs.vue?vue&type=template&id=7c000d5e&"
import script from "./BlokSectionFeatureTabs.vue?vue&type=script&lang=js&"
export * from "./BlokSectionFeatureTabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomLink: require('/builds/docue/website/components/CustomLink.vue').default})
