//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import blok from '~/mixins/blok'
export default {
  mixins: [blok],
  data () {
    return {
      activeFeature: null
    }
  },
  computed: {
    features () {
      return this.blok.features.map(f => ({
        ...f,
        image: this.$sb.parseImage(f.image)
      }))
    },
  },
  watch: {
    // `this.features` may update when receiving updates in storyblok live editing
    features () {
      if (this.activeFeature) {
        this.activeFeature = this.features.find(x => x._uid === this.activeFeature._uid)
      }
    },
  },
  mounted () {
    this.activeFeature = this.features[0]
  },
  methods: {
    activateFeature (e, feature) {
      this.activeFeature = feature
      this.$refs.tabs.scrollLeft = e.target.offsetLeft - 20
    }
  },
}
